$(document).ready(function() {
    $('#cookie-akkoord').click(function(e) {
        e.preventDefault();
        $.cookie('cookie-bar', 'true', {expires: 365, path: '/'});
        $.cookie('cookie-accept', 'true', {expires: 365, path: '/'});
        $('.cookie-bar').remove();
    });

    $('#cookie-close').click(function(e) {
        e.preventDefault();
        $('.cookie-bar').remove();
	    $.cookie('cookie-bar', 'true', {expires: 365, path: '/'});
    });
});
