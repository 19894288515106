
$(document).ready(function() {
    // URL validate
    $.validator.addMethod("complete_url", function(value, element, param) {
        return this.optional(element) || /^(www\.)(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)*(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(value);
    }, $.validator.messages.url);

    $.ajaxSetup({ cache: false });


    // SLIDER interactie
    $('.top-carrousel ul').bxSlider({
        mode: 'fade',
        pager: false,
        controls: true,
        auto: true,
        nextText: '',
        prevText: '',
        touchEnabled: true
    });
    $('.block').fitVids();

    equalHeights('.banner','.banners-122');
    equalHeights('.banner','.banners-123');

    /*
    Gmap3 functionality
    Needed for blocks: 130, 131
    */
    $('.gmap').each(function( index ) {
        var gmap_address = $(this).data('address');
        var gmap_key = $(this).data('gk');
        //console.log(gmap_address);
        var iw = $(this).width();
        var ih = $(this).height();
        var img = $("<img>");
        img.prop('src', 'https://maps.google.com/maps/api/staticmap?center=' + gmap_address +
                        '&zoom=12' +
                        '&scale=4' +
                        '&size=' + iw + 'x' + ih +
                        '&markers=size:large|color:0xff0000|label:|' + gmap_address +
                        '&key=' + gmap_key);

        var ahref = $('<a>');
        ahref.prop('target', '_blank');
        ahref.prop('href', 'https://www.google.com/maps/dir//' + gmap_address);
        img.appendTo(ahref);
        $(this).html(ahref);
    });

    $("#contact-form").validate({
        submitHandler: function(form) {
            $('.alert-danger', form).addClass('hidden');
            $('.alert-success', form).addClass('hidden');
            $.post(window.base_url +"engine/contact.php", $(form).serializeArray(), function(data){
                if(data.succes)
                {
                    $('.alert-success', form).removeClass('hidden');
                    $(form)[0].reset();
                } else
                {
                    $('.alert-danger', form).removeClass('hidden');
                }
            }, 'json');
        }
    });

    $("#tool-mc-dns").validate({
        submitHandler: function(form) {
            $('.alert-danger', form).addClass('hidden');
            $('.alert-success', form).addClass('hidden');
            $('#tool-mc-dns-results').removeClass('valid').removeClass('errors').removeClass('fail').addClass('hidden');
            $('#tool-mc-dns-results .report-spf').html('');
            $('#tool-mc-dns-results .report-dkim').html('');
            $('#tool-mc-dns-results .status').html('');
            $('#tool-mc-dns-results input[name=spf_record]').addClass('hidden');

            $.post(window.base_url +"engine/tool.mc-spf-dkim.php", $(form).serializeArray(), function(data){
                if(data.success) {
                    if ((data.spf_success) && (data.dkim_success)) {
                        $('#tool-mc-dns-results').addClass('valid');
                        $('#tool-mc-dns-results .status').text(data.domain + ' is goed ingericht');
                        $('#tool-mc-dns-results .report-spf').html(data.domain + ' heeft een goed SPF record<br>SPF record is:');
                        $('#tool-mc-dns-results input[name=spf_record]').val(data.spf).removeClass('hidden');;
                        $('#tool-mc-dns-results .report-dkim').html('Mailchimp DKIM record is aanwezig');
                    } else {
                        $('#tool-mc-dns-results').addClass('errors');
                        $('#tool-mc-dns-results .status').text(data.domain + ' heeft aandachts punten');
                        if (data.spf_success) {
                            $('#tool-mc-dns-results .report-spf').html(data.domain + ' heeft een goed SPF record<br>SPF record is:');
                            $('#tool-mc-dns-results input[name=spf_record]').val(data.spf).removeClass('hidden');;
                        } else {
                            $('#tool-mc-dns-results .report-spf').html('<span class="text-danger">' + data.spf_error + '</span><br>Voorstel voor SPF record:');
                            $('#tool-mc-dns-results input[name=spf_record]').val(data.spf_suggest).removeClass('hidden');
                        }
                        if (data.dkim_success) {
                            $('#tool-mc-dns-results .report-dkim').html('Mailchimp DKIM record is aanwezig');
                        } else {
                            $('#tool-mc-dns-results .report-dkim').html('<span class="text-danger">' + data.dkim_error + '</span><br><br>' + data.dkim_suggest);
                        }
                    }

                } else
                {
                    $('#tool-mc-dns-results').addClass('fail');
                    $('#tool-mc-dns-results .status').text('Helaas, ' + data.domain + ' heeft geen juist SPF record en DKIM record');
                }
                $('#tool-mc-dns-results').removeClass('hidden');
            }, 'json');
        }
    });

    $("#tool-https").validate({
        submitHandler: function(form) {
            $('.alert-danger', form).addClass('hidden');
            $('.alert-success', form).addClass('hidden');
            $('#tool-https-results').removeClass('valid').removeClass('errors').removeClass('fail').addClass('hidden');
            $('#tool-https-results .urls').html('');
            $('#tool-https-results .status').html('');
            $('#tool-https-results .report').html('');

            $.post(window.base_url +"engine/tool.https-secure-check.php", $(form).serializeArray(), function(data){
                if(data.success)
                {
                    if ((data.https_force) && (data.result_count === 0)) {
                        $('#tool-https-results').addClass('valid');
                        $('#tool-https-results .status').text(data.domain + ' draait volledig op HTTPS');
                        $('#tool-https-results .report').html(data.domain + ' is alleen te bereiken op HTTPS<br>Alle content wordt via HTTPS ingeladen');
                    } else {
                        $('#tool-https-results').addClass('errors');
                        $('#tool-https-results .status').text(data.domain + ' draait op HTTPS, maar met aandachtspunten');
                        if (data.https_force) {
                            $('#tool-https-results .report').html(data.domain + ' is alleen te bereiken op HTTPS')
                        } else {
                            $('#tool-https-results .report').html('<span class="text-danger">' + data.domain + ' is ook te bereiken op HTTP</span>')
                        }
                        if (data.result_count === 0) {
                            $('#tool-https-results .report').html($('#tool-https-results .report').html() + '<br>Alle content wordt via HTTPS ingeladen');
                        } else {
                            $('#tool-https-results .report').html($('#tool-https-results .report').html() + '<br><span class="text-danger">Er wordt data van ' + data.result_count + ' onveilige bronnen ingeladen.</span><br><br><b>Hier een overzicht van ' + data.results.length + ' onveilige bronnen:</b>');
                            $.each( data.results, function( key, value ) {
                                $('#tool-https-results .urls').append('<li>' + value + '</li>');
                            });
                        }
                    }

                } else
                {
                    $('#tool-https-results').addClass('fail');
                    $('#tool-https-results .status').text('Helaas, ' + data.domain + ' is niet beschikbaar op HTTPS');
                }
                $('#tool-https-results').removeClass('hidden');
            }, 'json');
        }
    });

    $("#mc-embedded-subscribe-form").validate({
        submitHandler: function(form) {
            $('.alert-danger', form).addClass('hidden');
            $('.alert-success', form).addClass('hidden');

            $.post(window.base_url +"engine/ebook.php", $(form).serializeArray(), function(data){
                if(data.success) {
                    $('.alert-success', form).removeClass('hidden');
                    $(form)[0].reset();
                } else {
                    $('.alert-danger', form).removeClass('hidden');
                }
            }, 'json');
        }
    });
});

$(document).scroll(function() {
    if ($(window).scrollTop() > $('#visual-element').height() * 0.7)
        $('body').addClass('in-scroll');
    else
        $('body').removeClass('in-scroll');
});

$( window ).resize(function() {
    equalHeights('.banner','.banners-122');
    equalHeights('.banner','.banners-123');
});



function initCoverflow(elm) {

    coverflow = new FWDSimple3DCoverflow(
        {
            //required settings
            coverflowHolderDivId: elm,
            coverflowDataListDivId:"coverflowData",
            displayType:"responsive",
            autoScale:"yes",
            coverflowWidth:940,
            coverflowHeight:560,
            skinPath:"images/coverflow/skin_white",

            //main settings
            backgroundColor:"transparent",
            backgroundImagePath:"",
            backgroundRepeat:"repeat-x",
            showDisplay2DAlways:"no",
            coverflowStartPosition:$('#'+elm).data('cf-startslide'),
            coverflowTopology:$('#'+elm).data('cf-mode'),
            coverflowXRotation:0,
            coverflowYRotation:0,
            numberOfThumbnailsToDisplayLeftAndRight:"5",
            infiniteLoop:$('#'+elm).data('cf-loop'),
            rightClickContextMenu:"default",

            //thumbnail settings
            thumbnailWidth:300,
            thumbnailHeight:500,
            thumbnailXOffset3D:86,
            thumbnailXSpace3D:78,
            thumbnailZOffset3D:200,
            thumbnailZSpace3D:93,
            thumbnailYAngle3D:0,
            thumbnailXOffset2D:20,
            thumbnailXSpace2D:30,
            thumbnailHoverOffset:100,
            thumbnailBorderSize:0,
            thumbnailBackgroundColor:"#999999",
            thumbnailBorderColor1:"#FFFFFF",
            thumbnailBorderColor2:"#000000",
            transparentImages:"yes",
            thumbnailsAlignment:"center",
            maxNumberOfThumbnailsOnMobile:13,
            showThumbnailsGradient:"yes",
            thumbnailGradientColor1:"rgba(0, 0, 0, 0)",
            thumbnailGradientColor2:"rgba(0, 0, 0, 0)",
            showText:"yes",
            textOffset:10,
            showThumbnailBoxShadow:"yes",
            thumbnailBoxShadowCss:"0 3px 4px 0 rgba(0,0,0,0.5)",
            showTooltip:"no",
            dynamicTooltip:"yes",
            showReflection:"no",
            reflectionHeight:60,
            reflectionDistance:0,
            reflectionOpacity:.4,

            //controls settings
            slideshowDelay:$('#'+elm).data('cf-pause'),
            autoplay:$('#'+elm).data('cf-autoplay'),
            disableNextAndPrevButtonsOnMobile:"no",
            controlsMaxWidth:700,
            slideshowTimerColor:"#FFFFFF",
            controlsPosition:"bottom",
            controlsOffset:15,
            showPrevButton:$('#'+elm).data('cf-controls'),
            showNextButton:$('#'+elm).data('cf-controls'),
            showSlideshowButton:$('#'+elm).data('cf-controls'),
            showScrollbar:$('#'+elm).data('cf-pager'),
            disableScrollbarOnMobile:"yes",
            enableMouseWheelScroll:"yes",
            scrollbarHandlerWidth:200,
            scrollbarTextColorNormal:"#000000",
            scrollbarTextColorSelected:"#FFFFFF",
            addKeyboardSupport:"yes",

            //categories settings
            showCategoriesMenu:"no",
            startAtCategory:1,
            categoriesMenuMaxWidth:700,
            categoriesMenuOffset:25,
            categoryColorNormal:"#999999",
            categoryColorSelected:"#FFFFFF",

            //lightbox settings
            addLightBoxKeyboardSupport:"yes",
            showLightBoxNextAndPrevButtons:"yes",
            showLightBoxZoomButton:"yes",
            showLightBoxInfoButton:"yes",
            showLightBoxSlideShowButton:"yes",
            showLightBoxInfoWindowByDefault:"no",
            slideShowAutoPlay:"no",
            lightBoxVideoAutoPlay:"no",
            lightBoxVideoWidth:640,
            lightBoxVideoHeight:480,
            lightBoxIframeWidth:800,
            lightBoxIframeHeight:600,
            lightBoxBackgroundColor:"#000000",
            lightBoxInfoWindowBackgroundColor:"#FFFFFF",
            lightBoxItemBorderColor1:"#fcfdfd",
            lightBoxItemBorderColor2:"#e4FFe4",
            lightBoxItemBackgroundColor:"#333333",
            lightBoxMainBackgroundOpacity:.8,
            lightBoxInfoWindowBackgroundOpacity:.9,
            lightBoxBorderSize:0,
            lightBoxBorderRadius:20,
            lightBoxSlideShowDelay:4000

        });
}

function createBXslider(elm){
    $(elm).each(function(index) {
        c_id = $(this).attr('id');
        //console.log(c_id);
        var options = {};
        options.mode = $(this).data('bx-mode');
        options.auto = $(this).data('bx-auto');
        options.pause = $(this).data('bx-pause');
        options.loop = $(this).data('bx-loop');
        options.pager = $(this).data('bx-pager');
        options.controls = $(this).data('bx-controls');
        options.ticker = $(this).data('bx-ticker');
        options.speed = $(this).data('bx-speed');
        options.minSlides  = $(this).data('bx-minslides') ? $(this).data('bx-minslides') : 1;
        options.maxSlides  = $(this).data('bx-maxslides') ? $(this).data('bx-maxslides') : 1;
        options.slideWidth  = $(this).data('bx-slidewidth') ? $(this).data('bx-slidewidth') : 0;

        options.nextText = '';
        options.prevText = '';
        console.log(options);
        $(this).bxSlider(options);
    });
    return;

    car_id = $(elm).attr('id');
    var cms_options = 'options_'+car_id;
    this[cms_options].value++;
    //console.log(this[cms_options]);
    var options = {};

    // auto
    if ('auto' in this[cms_options]) {
        switch(this[cms_options].auto){
            case '1':
                options.auto = true;
                break;
            default:
                options.auto = false;
        }
    }else{
        options.auto = true;
    }

    // mode
    if ('mode' in this[cms_options]) {
        switch(this[cms_options].mode){
            case '0':
                options.mode = 'fade';
                break;
            case '2':
                options.mode = 'vertical';
                break;
            case '3':
                options.mode = 'horizontal';
                options.ticker = true;
                options.speed = 8000;
                break;
            default:
                options.mode = 'horizontal';
        }
    }else{
        options.mode = 'horizontal';
    }

    //console.log(options);

    $('#'+car_id).bxSlider(options);
}

// set <img src> as a css background-image
/*function imagesToBackground(){
    $('.image-on-bg').each(function() {
        var imageUrl = $(this).find('img').first().attr('src');
        imageUrl = encodeURI(imageUrl);
        $(this).find('img').first().remove();
        $(this).css('background-image', 'url(' + imageUrl + ')');
    });
}*/

function imagesToBackground(){
    $('.image-to-bg').each(function() {
        //var imageHeight = $(this).attr('height');
        var imageUrl = $(this).attr('src');
        imageUrl = encodeURI(imageUrl);
        var parentElement = $(this).closest('div');
        parentClass = '.'+$(this).closest('section').attr('class').split(' ').pop();
        $(this).remove();
        parentElement.addClass('image-block').css('background-image', 'url(' + imageUrl + ')');
        equalHeights('.block > div', parentClass);
    });
}

function equalHeights(element, container){
    if (typeof container === 'undefined') {
        c = $(element).parent();
    } else {
        c = $(container);
    }


    var highestBox = 0;
    c.find(element).each(function(){
        if($(this).height() > highestBox){
            highestBox = $(this).height();
        }
    })
    c.find(element).height(highestBox);
}

/*
function placeholderFallback() {
    if(!Modernizr.input.placeholder) {
        $("input[placeholder], textarea[placeholder]").each(function() {
            if($(this).val()==""){
                $(this).val($(this).attr("placeholder"));
                $(this).focus(function(){
                    if($(this).val()==$(this).attr("placeholder")) {
                        $(this).val("");
                        $(this).removeClass('placeholder');
                    }
                });
                $(this).blur(function(){
                    if($(this).val()==""){
                        $(this).val($(this).attr("placeholder"));
                        $(this).addClass('placeholder');
                    }
                });
            }
        });
        $('form').submit(function(){
            // first do all the checking for required element and form validation.
            // Only remove placeholders before final submission
            var placeheld = $(this).find('[placeholder]');
            for(var i=0; i<placeheld.length; i++){
                if($(placeheld[i]).val() == $(placeheld[i]).attr('placeholder')) {
                    // if not required, set value to empty before submitting
                    $(placeheld[i]).attr('value','');
                }
            }
        });
    }
}*/

// Delay function
var delay = (function(){
    var timer = 0;
    return function(callback, ms){
        clearTimeout (timer);
        timer = setTimeout(callback, ms);
    };
})();

$.validator.setDefaults({
    highlight: function(element) {
        $(element).closest('.form-group').addClass('has-error');
    },
    unhighlight: function(element) {
        $(element).closest('.form-group').removeClass('has-error');
    },
    errorElement: 'span',
    errorClass: 'help-block',
    errorPlacement: function(error, element) {
        if(element.parent('.input-group').length) {
            //error.insertAfter(element.parent());
            error.appendTo(element.parent().parent());
        } else {
            error.insertAfter(element);
        }
    }
});

/*
Show on Scroll content
 */
var $window           = $(window),
    win_height_padded = $window.height() * 1.1,
    isTouch           = Modernizr.touch;

if (isTouch) { $('.revealOnScroll').addClass('animated'); }

$window.on('scroll', revealOnScroll);

function revealOnScroll() {
    var scrolled = $window.scrollTop(),
        win_height_padded = $window.height() * 1.1;
    win_height_padded = $window.height();
    // Showed...
    $(".animate-on-show:not(.animated)").each(function () {
        var $this     = $(this),
            offsetTop = $this.offset().top;

        if (scrolled + win_height_padded > offsetTop) {
            $this.removeClass('aanimate-on-show');
            window.setTimeout(function(){
                $this.addClass('animated ' + $this.data('animation'));
            }, 100);
        }
    });
    // Hidden...
    $(".animated").each(function (index) {
        var $this     = $(this),
            offsetTop = $this.offset().top;
        if (scrolled + win_height_padded < offsetTop) {
            $(this).removeClass('animated ' + $this.data('animation')).addClass('animate-on-show');
        }
    });
}

revealOnScroll();


function createStaticMarker( markerColor, markerLabel, lat, lng ){
    return "&markers=color:" + markerColor + "|label:" + markerLabel + "|" + lat + "," + lng;
}

function createStaticMap( position ){
    var lat = position.coords.latitude;
    var lng = position.coords.longitude;
    var zoom = 20;
    var sensor = true;

    var img = $("<img>");
    img.attr( { src: "http://maps.google.com/maps/api/staticmap?" +
    "center=" +
    lat + "," +
    lng +
    "&zoom=" + zoom +
    "&size=" + mapWidth + "x" + mapHeight +
    createStaticMarker( "blue", "1", lat, lng ) +
    "&sensor=" + sensor +
    "&key=vSSV407H5pdFdgtRuTJczTrWyYw="} );
    return img;
}

function showLocation( position ){
    var lat = position.coords.latitude;
    var lng = position.coords.longitude;
    var latlng = new google.maps.LatLng( lat, lng );

    $("#map").html( createStaticMap( position ) )

}

function mapError( e ){
    var error;
    switch( e.code ){
        case 1:
            error = "Permission Denied";
            break;
        case 2:
            error = "Network or Satellites Down";
            break;
        case 3:
            error = "GeoLocation timed out";
            break;
        case 0:
            error = "Other Error";
            break;
    }
    $("#map").html( error );
}